import i18n from "@/i18n";
import { markRaw, toRaw } from "vue";
import {
  Plus,
  Delete,
  BottomLeft,
  TopRight,
  Search,
  Download,
  Edit,
  Setting,
} from "@element-plus/icons-vue";
import api from "@/api";
import moment from "moment";
import { getUploadUrl } from "../../utils/tools";
const { t } = i18n.global;
export default {
  tableCols: [
    {
      name: t("rules.rRuleName"),
      filed: "rRuleName",
      // width: "170px",
    },

    {
      name: t("rules.rCompanyID"),
      filed: "cName",
      // width: "170px",
    },

    {
      name: t("rules.rLastUpdated"),
      filed: "rLastUpdated",
      // width: "170px",
    },
  ],
  tableOps: [
    {
      opType: "btn",
      icon: markRaw(Setting),
      name: t("commKey.SETTING"),
      type: "",
      click: (row, callback) => {
        // console.log("row", row);
        api.sys_rules_row({ id: row.ID }).then((res) => {
          // console.log(res.data.row, "11");
          let rule_row = res.data.data;
          callback({ type: "bind", row: rule_row });
        });
      },
    },
    {
      opType: "btn",
      icon: markRaw(Edit),
      name: t("commKey.Edit"),
      type: "",
      click: (row, callback) => {
        // console.log("row", row);
        api.sys_rules_row({ id: row.ID }).then((res) => {
          // console.log(res.data.row, "11");
          let rule_row = res.data.data;
          callback({ type: "edit", row: rule_row });
        });
      },
    },
    {
      opType: "del",
      icon: markRaw(Delete),
      name: t("commKey.Delete"),
      type: "danger",
      click: (row, callback) => {
        console.log("del", row);
        callback({ type: "delete", row: row });
        // activeKey.value = "test";
      },
    },
  ],
  searchCols: [
    // {
    //   type: "sel",
    //   title: t("commKey.COMPANYS"),
    //   key: "company_id",
    //   val: "",
    //   data: [],
    //   change: (val, callback) => {
    //     callback({ type: "groups", company_id: val });
    //     // console.log(val);
    //   },
    // },
    // {
    //   type: "sel",
    //   title: t("commKey.VEHGROUP"),
    //   key: "group_id",
    //   val: "",
    //   data: [],
    //   change: (val, callback) => {},
    // },
    {
      type: "ipt",
      title: t("commKey.keyname"),
      key: "keyname",
      val: "",
    },

    {
      type: "btn",
      btnType: "success",
      icon: markRaw(Search),
      title: t("commKey.Search"),
      click: (callback) => {
        callback({ type: "search" });
      },
    },
    {
      type: "btn",
      btnType: "primary",
      title: t("commKey.Add"),
      icon: markRaw(Plus),
      click: (callback) => {
        callback({ type: "add", row: { ID: 0 } });
      },
    },

    // {
    //   type: "btn",
    //   btnType: "danger",
    //   title: t("commKey.Delete"),
    //   icon: markRaw(Delete),
    //   click: (callback) => {
    //     callback({ type: "delete" });
    //   },
    // },
  ],
  // formCols: [
  //   {
  //     title: t("rules.rule_title"),
  //     children: [
  //       {
  //         type: "ipt",
  //         label: t("cars.vPlate"),
  //         field: "vPlate",
  //         placeholder: t("cars.vPlate"),
  //         val: "",
  //         required: true,
  //       },
  //       {
  //         type: "sel",
  //         label: t("cars.vVehType"),
  //         field: "vVehType",
  //         placeholder: t("cars.vVehType"),
  //         val: "",
  //         data: [],
  //         required: true,
  //       },
  //       {
  //         type: "sel",
  //         label: t("cars.vCompanyID"),
  //         field: "vCompanyID",
  //         placeholder: t("cars.vCompanyID"),
  //         val: "",
  //         required: true,
  //       },
  //     ],
  //   },
  // ],
  navForm: [
    {
      code: "1001",
      name: t("map.pilaoguize"),
      pos: "-179px -2px",
      pos1: "-179px -2px",
      pos2: "-179px -116px",
      cls: "pic1",
      form: [
        {
          title: t("rules.rule_title"),
          children: [
            {
              type: "ipt",
              label: t("rules.th_rule_name"),
              field: "RuleName",
              placeholder: t("rules.th_rule_name"),
              val: "",
              required: true,
              cls: "",
            },
            {
              type: "sel",
              label: t("rules.th_vehgroup"),
              field: "CompanyID",
              placeholder: t("rules.th_vehgroup"),
              val: "",
              required: false,
              cls: "",
            },
            {
              type: "ipt",
              label: t("rules.th_travel_time"),
              field: "travelTime",
              placeholder: t("rules.th_travel_time"),
              val: "240",
              required: false,
              unit: t("rules.travelTimeUnit"),
              cls: "ipt-alarm",
            },
            {
              type: "ipt",
              label: t("rules.th_rest_time"),
              field: "restTime",
              placeholder: t("rules.th_rest_time"),
              val: "20",
              required: false,
              unit: t("rules.travelTimeUnit"),
              cls: "ipt-alarm",
            },
            {
              type: "ipt",
              label: t("rules.th_min_speed"),
              field: "minSpeed",
              placeholder: t("rules.th_min_speed"),
              val: "0",
              required: false,
              unit: t("rules.minSpeedUnit1"),
              cls: "ipt-alarm",
            },
            {
              type: "time",
              label: t("rules.th_begin_time"),
              field: "beginTime",
              title_s: t("rules.th_begin_time"),
              title_e: t("rules.th_begin_time"),
              val: ["00:00:00", "23:59:59"],
              required: false,
            },
            {
              type: "textarea",
              label: t("rules.th_voice"),
              field: "voice",
              placeholder: t("rules.th_voice"),
              val: "",
              required: false,
            },
          ],
        },
        {
          title: t("rules.panel_title"),
          children: [
            {
              cls: "ipt-link",
              type: "radio",
              label: t("rules.th_camera"),
              field: "camera",
              placeholder: t("rules.th_camera"),
              val: "0",
              required: false,
              data: [
                {
                  name: t("rules.label_camera_yes"),
                  value: "1",
                },
                {
                  name: t("rules.label_camera_no"),
                  value: "0",
                },
              ],
            },
            {
              chk_all: 1,
              cls: "ipt-link",
              type: "checkbox",
              label: t("rules.th_cameraWebcam"),
              field: "cameraChannel",
              placeholder: t("rules.th_cameraWebcam"),
              val: [],
              required: false,
              data: [
                {
                  name: "CH1",
                  value: "1",
                },
                {
                  name: "CH2",
                  value: "2",
                },
                {
                  name: "CH3",
                  value: "3",
                },
                {
                  name: "CH4",
                  value: "4",
                },
                {
                  name: "CH5",
                  value: "5",
                },
                {
                  name: "CH6",
                  value: "6",
                },
                {
                  name: "CH7",
                  value: "7",
                },
                {
                  name: "CH8",
                  value: "8",
                },
              ],
            },
            {
              cls: "ipt-link",
              type: "ipt",
              label: t("rules.th_video"),
              field: "video",
              placeholder: t("rules.th_video"),
              val: "0",
              required: false,
              unit: t("rules.videoUnit"),
            },
            {
              chk_all: 1,
              cls: "ipt-link",
              type: "checkbox",
              label: t("rules.th_videoWebcam"),
              field: "videoChannel",
              placeholder: t("rules.th_videoWebcam"),
              val: [],
              required: false,
              data: [
                {
                  name: "CH1",
                  value: "1",
                },
                {
                  name: "CH2",
                  value: "2",
                },
                {
                  name: "CH3",
                  value: "3",
                },
                {
                  name: "CH4",
                  value: "4",
                },
                {
                  name: "CH5",
                  value: "5",
                },
                {
                  name: "CH6",
                  value: "6",
                },
                {
                  name: "CH7",
                  value: "7",
                },
                {
                  name: "CH8",
                  value: "8",
                },
              ],
            },
          ],
        },
        {
          title: t("rules.panel_title_info"),
          children: [
            {
              cls: "ipt-link",
              type: "textarea",
              label: t("rules.th_mail"),
              field: "mail",
              placeholder: t("rules.th_mail"),
              val: "",
              required: false,
              unit: t("rules.mailTip"),
            },
          ],
        },
      ],
    },
    {
      code: "1002",
      name: t("map.jinruguize"),
      pos: "-420px -2px",
      pos1: "-420px -2px",
      pos2: "-340px -116px",
      cls: "pic1",
      form: [
        {
          title: t("rules.rule_title"),
          children: [
            {
              type: "ipt",
              label: t("rules.th_rule_name"),
              field: "RuleName",
              placeholder: t("rules.th_rule_name"),
              val: "",
              required: false,
            },
            {
              type: "sel",
              label: t("rules.th_vehgroup"),
              field: "CompanyID",
              placeholder: t("rules.th_vehgroup"),
              val: "",
              required: false,
              change: (evt, it, callback, ruleForm) => {
                // console.log("wxw");
                api.item_get_rect({ cpy_id: evt }).then((res) => {
                  let ay = [];
                  if (res.data.data.length > 0) {
                    res.data.data.forEach((it) => {
                      it.name = it.rRectName;
                      it.value = it.ID;
                      ay.push(it);
                    });
                  }
                  ruleForm.areaNameValue = "";
                  callback({
                    type: "rect",
                    rows: ay,
                    to: "areaNameValue",
                  });
                });
              },
            },
            {
              cls: "ipt-alarm",
              type: "sel",
              label: t("rules.th_area_name"),
              field: "areaNameValue",
              placeholder: t("rules.th_area_name"),
              val: "",
              required: false,
              data: [],
            },

            {
              type: "time",
              label: t("rules.th_begin_time"),
              field: "beginTime",
              title_s: t("rules.th_begin_time"),
              title_e: t("rules.th_begin_time"),
              val: "",
              required: false,
            },
            {
              type: "textarea",
              label: t("rules.th_voice"),
              field: "voice",
              placeholder: t("rules.th_voice"),
              val: "",
              required: false,
            },
          ],
        },
        {
          title: t("rules.panel_title"),
          children: [
            {
              cls: "ipt-link",
              type: "radio",
              label: t("rules.th_camera"),
              field: "camera",
              placeholder: t("rules.th_camera"),
              val: "0",
              required: false,
              data: [
                {
                  name: t("rules.label_camera_yes"),
                  value: "1",
                },
                {
                  name: t("rules.label_camera_no"),
                  value: "0",
                },
              ],
            },
            {
              chk_all: 1,
              cls: "ipt-link",
              type: "checkbox",
              label: t("rules.th_cameraWebcam"),
              field: "cameraChannel",
              placeholder: t("rules.th_cameraWebcam"),
              val: "0",
              required: false,
              data: [
                {
                  name: "CH1",
                  value: "1",
                },
                {
                  name: "CH2",
                  value: "2",
                },
                {
                  name: "CH3",
                  value: "3",
                },
                {
                  name: "CH4",
                  value: "4",
                },
                {
                  name: "CH5",
                  value: "5",
                },
                {
                  name: "CH6",
                  value: "6",
                },
                {
                  name: "CH7",
                  value: "7",
                },
                {
                  name: "CH8",
                  value: "8",
                },
              ],
            },
            {
              cls: "ipt-link",
              type: "ipt",
              label: t("rules.th_video"),
              field: "video",
              placeholder: t("rules.th_video"),
              val: "",
              required: false,
              unit: t("rules.videoUnit"),
            },
            {
              chk_all: 1,
              cls: "ipt-link",
              type: "checkbox",
              label: t("rules.th_videoWebcam"),
              field: "videoChannel",
              placeholder: t("rules.th_videoWebcam"),
              val: "0",
              required: false,
              data: [
                {
                  name: "CH1",
                  value: "1",
                },
                {
                  name: "CH2",
                  value: "2",
                },
                {
                  name: "CH3",
                  value: "3",
                },
                {
                  name: "CH4",
                  value: "4",
                },
                {
                  name: "CH5",
                  value: "5",
                },
                {
                  name: "CH6",
                  value: "6",
                },
                {
                  name: "CH7",
                  value: "7",
                },
                {
                  name: "CH8",
                  value: "8",
                },
              ],
            },
          ],
        },
        {
          title: t("rules.panel_title_info"),
          children: [
            {
              cls: "ipt-link",
              type: "textarea",
              label: t("rules.th_mail"),
              field: "mail",
              placeholder: t("rules.th_mail"),
              val: "",
              required: false,
              unit: t("rules.mailTip"),
            },
          ],
        },
      ],
    },
    {
      code: "1003",
      name: t("map.jinchuguize"),
      pos: "-340px -2px",
      pos1: "-340px -2px",
      pos2: "-420px -116px",
      cls: "pic1",
      form: [
        {
          title: t("rules.rule_title"),
          children: [
            {
              type: "ipt",
              label: t("rules.th_rule_name"),
              field: "RuleName",
              placeholder: t("rules.th_rule_name"),
              val: "",
              required: false,
            },
            {
              type: "sel",
              label: t("rules.th_vehgroup"),
              field: "CompanyID",
              placeholder: t("rules.th_vehgroup"),
              val: "",
              required: false,
              change: (evt, it, callback, ruleForm) => {
                // console.log("wxw");
                api.item_get_rect({ cpy_id: evt }).then((res) => {
                  let ay = [];
                  if (res.data.data.length > 0) {
                    res.data.data.forEach((it) => {
                      it.name = it.rRectName;
                      it.value = it.ID;
                      ay.push(it);
                    });
                  }
                  ruleForm.areaNameValue = "";
                  callback({
                    type: "rect",
                    rows: ay,
                    to: "areaNameValue",
                  });
                });
              },
            },
            {
              cls: "ipt-alarm",
              type: "sel",
              label: t("rules.th_area_name"),
              field: "areaNameValue",
              placeholder: t("rules.th_area_name"),
              val: "",
              required: false,
            },

            {
              type: "time",
              label: t("rules.th_begin_time"),
              field: "beginTime",
              title_s: t("rules.th_begin_time"),
              title_e: t("rules.th_begin_time"),
              val: "",
              required: false,
            },
            {
              type: "textarea",
              label: t("rules.th_voice"),
              field: "voice",
              placeholder: t("rules.th_voice"),
              val: "",
              required: false,
            },
          ],
        },
        {
          title: t("rules.panel_title"),
          children: [
            {
              cls: "ipt-link",
              type: "radio",
              label: t("rules.th_camera"),
              field: "camera",
              placeholder: t("rules.th_camera"),
              val: "0",
              required: false,
              data: [
                {
                  name: t("rules.label_camera_yes"),
                  value: "1",
                },
                {
                  name: t("rules.label_camera_no"),
                  value: "0",
                },
              ],
            },
            {
              chk_all: 1,
              cls: "ipt-link",
              type: "checkbox",
              label: t("rules.th_cameraWebcam"),
              field: "cameraChannel",
              placeholder: t("rules.th_cameraWebcam"),
              val: "0",
              required: false,
              data: [
                {
                  name: "CH1",
                  value: "1",
                },
                {
                  name: "CH2",
                  value: "2",
                },
                {
                  name: "CH3",
                  value: "3",
                },
                {
                  name: "CH4",
                  value: "4",
                },
                {
                  name: "CH5",
                  value: "5",
                },
                {
                  name: "CH6",
                  value: "6",
                },
                {
                  name: "CH7",
                  value: "7",
                },
                {
                  name: "CH8",
                  value: "8",
                },
              ],
            },
            {
              cls: "ipt-link",
              type: "ipt",
              label: t("rules.th_video"),
              field: "video",
              placeholder: t("rules.th_video"),
              val: "",
              required: false,
              unit: t("rules.videoUnit"),
            },
            {
              chk_all: 1,
              cls: "ipt-link",
              type: "checkbox",
              label: t("rules.th_videoWebcam"),
              field: "videoChannel",
              placeholder: t("rules.th_videoWebcam"),
              val: "0",
              required: false,
              data: [
                {
                  name: "CH1",
                  value: "1",
                },
                {
                  name: "CH2",
                  value: "2",
                },
                {
                  name: "CH3",
                  value: "3",
                },
                {
                  name: "CH4",
                  value: "4",
                },
                {
                  name: "CH5",
                  value: "5",
                },
                {
                  name: "CH6",
                  value: "6",
                },
                {
                  name: "CH7",
                  value: "7",
                },
                {
                  name: "CH8",
                  value: "8",
                },
              ],
            },
          ],
        },
        {
          title: t("rules.panel_title_info"),
          children: [
            {
              cls: "ipt-link",
              type: "textarea",
              label: t("rules.th_mail"),
              field: "mail",
              placeholder: t("rules.th_mail"),
              val: "",
              required: false,
              unit: t("rules.mailTip"),
            },
          ],
        },
      ],
    },
    {
      code: "1004",
      name: t("map.quyuxiansu"),
      pos: "-1162px -2px",
      pos1: "-1162px -2px",
      pos2: "-1162px -116px",
      cls: "pic1",
      form: [
        {
          title: t("rules.rule_title"),
          children: [
            {
              type: "ipt",
              label: t("rules.th_rule_name"),
              field: "RuleName",
              placeholder: t("rules.th_rule_name"),
              val: "",
              required: false,
            },
            {
              type: "sel",
              label: t("rules.th_vehgroup"),
              field: "CompanyID",
              placeholder: t("rules.th_vehgroup"),
              val: "",
              required: false,
              change: (evt, it, callback, ruleForm) => {
                // console.log("wxw");
                api.item_get_rect({ cpy_id: evt }).then((res) => {
                  let ay = [];
                  if (res.data.data.length > 0) {
                    res.data.data.forEach((it) => {
                      it.name = it.rRectName;
                      it.value = it.ID;
                      ay.push(it);
                    });
                  }
                  ruleForm.areaNameValue = "";
                  callback({
                    type: "rect",
                    rows: ay,
                    to: "areaNameValue",
                  });
                });
              },
            },
            {
              cls: "ipt-alarm",
              type: "sel",
              label: t("rules.th_area_name"),
              field: "areaNameValue",
              placeholder: t("rules.th_area_name"),
              val: "",
              required: false,
            },
            {
              cls: "ipt-alarm",
              type: "ipt",
              label: t("rules.th_maxSpeed"),
              field: "maxSpeed",
              placeholder: t("rules.th_maxSpeed"),
              val: "120",
              required: false,
              unit: t("rules.maxSpeedUnit1"),
            },
            {
              cls: "ipt-alarm",
              type: "ipt",
              label: t("rules.th_minSpeed"),
              field: "minSpeed",
              placeholder: t("rules.th_minSpeed"),
              val: "0",
              required: false,
              unit: t("rules.maxSpeedUnit1"),
            },
            {
              type: "time",
              label: t("rules.th_begin_time"),
              field: "beginTime",
              title_s: t("rules.th_begin_time"),
              title_e: t("rules.th_begin_time"),
              val: "",
              required: false,
            },
            {
              type: "textarea",
              label: t("rules.th_voice"),
              field: "voice",
              placeholder: t("rules.th_voice"),
              val: "",
              required: false,
            },
          ],
        },
        {
          title: t("rules.panel_title"),
          children: [
            {
              cls: "ipt-link",
              type: "radio",
              label: t("rules.th_camera"),
              field: "camera",
              placeholder: t("rules.th_camera"),
              val: "0",
              required: false,
              data: [
                {
                  name: t("rules.label_camera_yes"),
                  value: "1",
                },
                {
                  name: t("rules.label_camera_no"),
                  value: "0",
                },
              ],
            },
            {
              chk_all: 1,
              cls: "ipt-link",
              type: "checkbox",
              label: t("rules.th_cameraWebcam"),
              field: "cameraChannel",
              placeholder: t("rules.th_cameraWebcam"),
              val: "0",
              required: false,
              data: [
                {
                  name: "CH1",
                  value: "1",
                },
                {
                  name: "CH2",
                  value: "2",
                },
                {
                  name: "CH3",
                  value: "3",
                },
                {
                  name: "CH4",
                  value: "4",
                },
                {
                  name: "CH5",
                  value: "5",
                },
                {
                  name: "CH6",
                  value: "6",
                },
                {
                  name: "CH7",
                  value: "7",
                },
                {
                  name: "CH8",
                  value: "8",
                },
              ],
            },
            {
              cls: "ipt-link",
              type: "ipt",
              label: t("rules.th_video"),
              field: "video",
              placeholder: t("rules.th_video"),
              val: "",
              required: false,
              unit: t("rules.videoUnit"),
            },
            {
              chk_all: 1,
              cls: "ipt-link",
              type: "checkbox",
              label: t("rules.th_videoWebcam"),
              field: "videoChannel",
              placeholder: t("rules.th_videoWebcam"),
              val: "0",
              required: false,
              data: [
                {
                  name: "CH1",
                  value: "1",
                },
                {
                  name: "CH2",
                  value: "2",
                },
                {
                  name: "CH3",
                  value: "3",
                },
                {
                  name: "CH4",
                  value: "4",
                },
                {
                  name: "CH5",
                  value: "5",
                },
                {
                  name: "CH6",
                  value: "6",
                },
                {
                  name: "CH7",
                  value: "7",
                },
                {
                  name: "CH8",
                  value: "8",
                },
              ],
            },
          ],
        },
        {
          title: t("rules.panel_title_info"),
          children: [
            {
              cls: "ipt-link",
              type: "textarea",
              label: t("rules.th_mail"),
              field: "mail",
              placeholder: t("rules.th_mail"),
              val: "",
              required: false,
              unit: t("rules.mailTip"),
            },
          ],
        },
      ],
    },
    {
      code: "1009",
      name: t("map.yejianxingche"),
      pos: "-1312px -2px",
      pos1: "-1312px -2px",
      pos2: "-1312px -116px",
      cls: "pic1",
      form: [
        {
          title: t("rules.rule_title"),
          children: [
            {
              type: "ipt",
              label: t("rules.th_rule_name"),
              field: "RuleName",
              placeholder: t("rules.th_rule_name"),
              val: "",
              required: false,
            },
            {
              type: "sel",
              label: t("rules.th_vehgroup"),
              field: "CompanyID",
              placeholder: t("rules.th_vehgroup"),
              val: "",
              required: false,
            },

            {
              cls: "ipt-alarm",
              type: "ipt",
              label: t("rules.th_maxSpeed"),
              field: "maxSpeed",
              placeholder: t("rules.th_maxSpeed"),
              val: "240",
              required: false,
              unit: t("rules.maxSpeedUnit1"),
            },
            {
              cls: "ipt-alarm",
              type: "ipt",
              label: t("rules.th_minSpeed"),
              field: "minSpeed",
              placeholder: t("rules.th_minSpeed"),
              val: "240",
              required: false,
              unit: t("rules.maxSpeedUnit1"),
            },
            {
              type: "time",
              label: t("rules.th_begin_time"),
              field: "beginTime",
              title_s: t("rules.th_begin_time"),
              title_e: t("rules.th_begin_time"),
              val: "",
              required: false,
            },
            {
              type: "textarea",
              label: t("rules.th_voice"),
              field: "voice",
              placeholder: t("rules.th_voice"),
              val: "",
              required: false,
            },
          ],
        },
        {
          title: t("rules.panel_title"),
          children: [
            {
              cls: "ipt-link",
              type: "radio",
              label: t("rules.th_camera"),
              field: "camera",
              placeholder: t("rules.th_camera"),
              val: "0",
              required: false,
              data: [
                {
                  name: t("rules.label_camera_yes"),
                  value: "1",
                },
                {
                  name: t("rules.label_camera_no"),
                  value: "0",
                },
              ],
            },
            {
              chk_all: 1,
              cls: "ipt-link",
              type: "checkbox",
              label: t("rules.th_cameraWebcam"),
              field: "cameraChannel",
              placeholder: t("rules.th_cameraWebcam"),
              val: "0",
              required: false,
              data: [
                {
                  name: "CH1",
                  value: "1",
                },
                {
                  name: "CH2",
                  value: "2",
                },
                {
                  name: "CH3",
                  value: "3",
                },
                {
                  name: "CH4",
                  value: "4",
                },
                {
                  name: "CH5",
                  value: "5",
                },
                {
                  name: "CH6",
                  value: "6",
                },
                {
                  name: "CH7",
                  value: "7",
                },
                {
                  name: "CH8",
                  value: "8",
                },
              ],
            },
            {
              cls: "ipt-link",
              type: "ipt",
              label: t("rules.th_video"),
              field: "video",
              placeholder: t("rules.th_video"),
              val: "",
              required: false,
              unit: t("rules.videoUnit"),
            },
            {
              chk_all: 1,
              cls: "ipt-link",
              type: "checkbox",
              label: t("rules.th_videoWebcam"),
              field: "videoChannel",
              placeholder: t("rules.th_videoWebcam"),
              val: "0",
              required: false,
              data: [
                {
                  name: "CH1",
                  value: "1",
                },
                {
                  name: "CH2",
                  value: "2",
                },
                {
                  name: "CH3",
                  value: "3",
                },
                {
                  name: "CH4",
                  value: "4",
                },
                {
                  name: "CH5",
                  value: "5",
                },
                {
                  name: "CH6",
                  value: "6",
                },
                {
                  name: "CH7",
                  value: "7",
                },
                {
                  name: "CH8",
                  value: "8",
                },
              ],
            },
          ],
        },
        {
          title: t("rules.panel_title_info"),
          children: [
            {
              cls: "ipt-link",
              type: "textarea",
              label: t("rules.th_mail"),
              field: "mail",
              placeholder: t("rules.th_mail"),
              val: "",
              required: false,
              unit: t("rules.mailTip"),
            },
          ],
        },
      ],
    },
    {
      code: "1010",
      name: t("map.dingshipaizhao"),
      pos: "-260px -2px",
      pos1: "-260px -2px",
      pos2: "-260px -116px",
      cls: "pic1",
      form: [
        {
          title: t("rules.rule_title"),
          children: [
            {
              type: "ipt",
              label: t("rules.th_rule_name"),
              field: "RuleName",
              placeholder: t("rules.th_rule_name"),
              val: "",
              required: false,
            },
            {
              type: "sel",
              label: t("rules.th_vehgroup"),
              field: "CompanyID",
              placeholder: t("rules.th_vehgroup"),
              val: "",
              required: false,
            },
            {
              cls: "ipt-alarm",
              type: "time",
              label: t("rules.th_begin_time_a"),
              field: "beginTime1",
              title_s: t("rules.th_begin_time"),
              title_e: t("rules.th_begin_time"),
              val: ["00:00:00", "23:59:59"],
              required: false,
            },
            {
              cls: "ipt-alarm",
              type: "time",
              label: t("rules.th_begin_time"),
              field: "beginTime2",
              title_s: t("rules.th_begin_time"),
              title_e: t("rules.th_begin_time"),
              val: ["00:00:00", "23:59:59"],
              required: false,
            },
            {
              cls: "ipt-alarm",
              type: "sel",
              label: t("rules.th_keyType"),
              field: "modeTypeValue",
              placeholder: t("rules.th_keyType"),
              val: "0",
              required: false,
            },
            {
              cls: "ipt-alarm",
              type: "ipt",
              label: t("rules.th_time_jg"),
              field: "interval",
              placeholder: t("rules.th_time_jg"),
              val: "60",
              required: false,
              unit: t("rules.offsetWidthUnit"),
            },
          ],
        },
      ],
    },
    {
      code: "1012",
      name: "Wifi" + t("map.xiazai"),
      pos: "-82px -494px",
      pos1: "-82px -494px",
      pos2: "-82px -598px",
      cls: "pic2",
      form: [
        {
          title: t("rules.rule_title"),
          children: [
            {
              type: "ipt",
              label: t("rules.th_rule_name"),
              field: "RuleName",
              placeholder: t("rules.th_rule_name"),
              val: "",
              required: false,
            },
            {
              type: "sel",
              label: t("rules.th_vehgroup"),
              field: "CompanyID",
              placeholder: t("rules.th_vehgroup"),
              val: "",
              required: false,
            },
            {
              cls: "ipt-alarm",
              type: "time",
              label: t("rules.th_begin_time_a"),
              field: "beginTime1",
              title_s: t("rules.th_begin_time"),
              title_e: t("rules.th_begin_time"),
              val: "",
              required: false,
            },
            {
              cls: "ipt-alarm",
              type: "time",
              label: t("rules.th_begin_time"),
              field: "beginTime2",
              title_s: t("rules.th_begin_time"),
              title_e: t("rules.th_begin_time"),
              val: "",
              required: false,
            },
            {
              cls: "ipt-alarm",
              type: "sel",
              label: t("rules.th_keyType_xz"),
              field: "downType",
              placeholder: t("rules.th_keyType_xz"),
              val: "0",
              required: false,
            },
          ],
        },
      ],
    },
    {
      code: "1014",
      name: t("map.baojingliandong"),
      pos: "-164px -494px",
      pos1: "-164px -494px",
      pos2: "-164px -598px",
      cls: "pic2",
      form: [
        {
          title: t("rules.rule_title"),
          children: [
            {
              type: "ipt",
              label: t("rules.th_rule_name"),
              field: "RuleName",
              placeholder: t("rules.th_rule_name"),
              val: "",
              required: false,
            },
            {
              type: "checkbox",
              label: t("rules.labelAlarmSelect"),
              field: "alarmTypes",
              placeholder: t("rules.labelAlarmSelect"),
              cls: "ipt-alarm",
              val: [],
              required: false,
              data: [
                {
                  name: t("rules.jj"),
                  value: "1",
                },
                {
                  name: t("rules.pz"),
                  value: "2",
                },
                {
                  name: t("rules.cs"),
                  value: "3",
                },
                {
                  name: t("rules.cpcw"),
                  value: "4",
                },
                {
                  name: t("rules.lxls"),
                  value: "5",
                },
                {
                  name: t("rules.cclljg"),
                  value: "6",
                },
              ],
            },
            {
              type: "time",
              label: t("rules.th_begin_time"),
              field: "beginTime",
              title_s: t("rules.th_begin_time"),
              title_e: t("rules.th_begin_time"),
              val: [],
              required: false,
            },
            {
              type: "textarea",
              label: t("rules.th_voice"),
              field: "voice",
              placeholder: t("rules.th_voice"),
              val: "",
              required: false,
            },
          ],
        },
        {
          title: t("rules.panel_title"),
          children: [
            {
              cls: "ipt-link",
              type: "radio",
              label: t("rules.th_camera"),
              field: "camera",
              placeholder: t("rules.th_camera"),
              val: "0",
              required: false,
              data: [
                {
                  name: t("rules.label_camera_yes"),
                  value: "1",
                },
                {
                  name: t("rules.label_camera_no"),
                  value: "0",
                },
              ],
            },
            {
              chk_all: 1,
              cls: "ipt-link",
              type: "checkbox",
              label: t("rules.th_cameraWebcam"),
              field: "cameraChannel",
              placeholder: t("rules.th_cameraWebcam"),
              val: "0",
              required: false,
              data: [
                {
                  name: "CH1",
                  value: "1",
                },
                {
                  name: "CH2",
                  value: "2",
                },
                {
                  name: "CH3",
                  value: "3",
                },
                {
                  name: "CH4",
                  value: "4",
                },
                {
                  name: "CH5",
                  value: "5",
                },
                {
                  name: "CH6",
                  value: "6",
                },
                {
                  name: "CH7",
                  value: "7",
                },
                {
                  name: "CH8",
                  value: "8",
                },
              ],
            },
            {
              cls: "ipt-link",
              type: "ipt",
              label: t("rules.th_video"),
              field: "video",
              placeholder: t("rules.th_video"),
              val: "10",
              required: false,
            },
            {
              chk_all: 1,
              cls: "ipt-link",
              type: "checkbox",
              label: t("rules.th_videoWebcam"),
              field: "videoChannel",
              placeholder: t("rules.th_videoWebcam"),
              val: "0",
              required: false,
              data: [
                {
                  name: "CH1",
                  value: "1",
                },
                {
                  name: "CH2",
                  value: "2",
                },
                {
                  name: "CH3",
                  value: "3",
                },
                {
                  name: "CH4",
                  value: "4",
                },
                {
                  name: "CH5",
                  value: "5",
                },
                {
                  name: "CH6",
                  value: "6",
                },
                {
                  name: "CH7",
                  value: "7",
                },
                {
                  name: "CH8",
                  value: "8",
                },
              ],
            },
          ],
        },
        {
          title: t("rules.panel_title_info"),
          children: [
            {
              cls: "ipt-link",
              type: "textarea",
              label: t("rules.th_mail"),
              field: "mail",
              placeholder: t("rules.th_mail"),
              val: "",
              required: false,
              unit: t("rules.mailTip"),
            },
          ],
        },
      ],
    },
  ],
};
