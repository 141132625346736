<template>
  <div class="gps-page">
    <div class="navs">
      <div
        class="item"
        v-for="it in mainNav"
        :key="it.code"
        @click="clickTab(it)"
      >
        <div :class="it.cls" :style="{ backgroundPosition: it.pos }"></div>
        <div class="txt">{{ it.name }}</div>
      </div>
    </div>
    <view-grid
      :tableCols="tableCols"
      :tableOps="tableOps"
      :query="query"
      :tableData="tableData"
      :total="total"
      :searchCols="searchCols"
      :selection="true"
      :op-width="250"
      @callbackEvent="callbackEvent"
      @handleCurrentChange="handleCurrentChange"
    ></view-grid>
    <component
      @saveEvent="saveEvent"
      @formEvent="formEvent"
      ref="refAnyCom"
      :formArray="formArray"
      :is="currentCopmonent"
      :row="curr_row"
      :dialogTitle="dialogTitle"
      :ruleType="ruleType"
    />
    <el-drawer
      v-model="drawerShow"
      :title="rule_title"
      direction="rtl"
      size="70%"
      id="aa"
    >
      <view-grid
        :tableCols="tableCols_cars"
        :query="query_cars"
        :tableData="tableData_cars"
        :total="total_cars"
        :searchCols="searchCols_cars"
        :selection="true"
        @callbackEvent="callbackEvent_cars"
        @handleCurrentChange="handleCurrentChange_cars"
      ></view-grid>
    </el-drawer>
    <!-- <div class="search">
      <div class="it">
        <el-input v-model="search_val" placeholder="" clearable></el-input>
      </div>
      <div class="it">
        <el-button type="success" round :icon="Search">{{ $t("map.track_cx")}}</el-button>
      </div>
      <div class="it">
        <el-button type="primary" round :icon="Plus">{{ $t("map.tianjia")}}</el-button>
      </div>
    </div>
    <div class="bbl"></div> -->
  </div>
</template>

<script setup>
import ViewGrid from "./components/ViewGridRule.vue";
import {
  ref,
  defineAsyncComponent,
  watch,
  getCurrentInstance,
  shallowRef,
  reactive,
  onMounted,
  toRaw,
  markRaw,
  // toRef,
} from "vue";
import { useI18n } from "vue-i18n";
import cars from "./RulesData";
import { ElNotification, ElMessageBox } from "element-plus";
import { Search, Setting } from "@element-plus/icons-vue";
import GpsSocket from "../../utils/GpsSocket";
import VueCookies from "vue-cookies";
import refresh_table from "../../utils/refrshTable";

const { t } = useI18n();
const refAnyCom = ref();
let { proxy } = getCurrentInstance();
const ruleType = ref("");
const rule_title = ref("");

// const tableOps_cars = ref([]);
const query_cars = ref({ rp: 25, page: 1 });
const drawerShow = ref(false);
const tableCols_cars = ref([
  {
    name: t("cars.vPlate"),
    filed: "vPlate",
    width: "170px",
  },
  {
    name: t("cars.vCompanyID"),
    filed: "cName",
    width: "170px",
  },
  {
    name: t("cars.vDeviceID"),
    filed: "dName",
    width: "170px",
  },
  {
    name: t("cars.sNo"),
    filed: "vSimNo",
    width: "170px",
  },
  {
    name: t("cars.vLastUpdated"),
    filed: "vLastUpdated",
    width: "170px",
  },
]);
const tableData_cars = ref([]);
const total_cars = ref(0);
const searchCols_cars = ref([
  {
    type: "sel",
    title: t("commKey.ZHUANGTAI"),
    key: "search_state",
    val: "",
    data: [],
    change: (val) => {
      query_cars.value.rv_zt = val;
      getList_cars();
    },
  },
  {
    type: "sel",
    title: t("commKey.QXZCZ"),
    key: "group_val",
    val: "",
    data: [],
  },
  {
    type: "ipt",
    title: t("commKey.CHEPAIHAO"),
    key: "vPlate_val",
    val: "",
  },
  {
    type: "btn",
    btnType: "success",
    icon: markRaw(Search),
    title: t("commKey.Search"),
    click: (callback) => {
      callback({ type: "search" });
    },
  },
  {
    type: "btn",
    btnType: "success",
    icon: markRaw(Setting),
    title: t("commKey.BINDCAR"),
    click: (callback) => {
      callback({ type: "bindcar" });
    },
  },
  {
    type: "btn",
    btnType: "success",
    icon: markRaw(Setting),
    title: t("commKey.UNBINDCAR"),
    click: (callback) => {
      callback({ type: "unbindcar" });
    },
  },
]);
console.log(tableCols_cars);

//动态组件
const activeKey = ref("RulesForm");
let currentCopmonent = shallowRef({});
currentCopmonent.value = defineAsyncComponent(() =>
  import(`./components/${activeKey.value}.vue`)
);
watch(
  () => activeKey.value,
  () => {
    currentCopmonent.value = defineAsyncComponent(() =>
      import(`./components/${activeKey.value}.vue`)
    );
  }
);

const dialogTitle = ref("");

//表格显示的列
const tableCols = ref(cars.tableCols);
//表格操作列
const tableOps = ref(cars.tableOps);

const row = {};
const curr_row = ref(row);

const total = ref(0);

const handleSizeChange = (val) => {
  console.log(`${val} items per page`);
};
const handleCurrentChange = (val) => {
  query.page = val;
  getList();
};

//查询列
const searchCols = ref(cars.searchCols);

const tableData = ref([]);
const query = reactive({ rp: 25, page: 1, qtype: 1001 });
// const ay_company = [];

const getList = () => {
  proxy.$api.sys_rules_list(query).then((res) => {
    tableData.value = res.data.data;
    total.value = res.data.pagination.totalRecords;
  });
  // proxy.$api.item_get_company({}).then((res) => {
  //   let ay = res.data.data;
  //   if (ay && ay.length > 0) {
  //     ay.forEach((it) => {
  //       it.name = it.cName;
  //       it.value = it.id;
  //       ay_company.push(it);
  //     });
  //   }
  // });
};
const init = () => {
  proxy.$api.sys_get_bases({ flag: ["zpms", "xzlx", "fpzt"] }).then((res) => {
    mainNav.forEach((ix) => {
      ix.form.forEach((iy) => {
        iy.children.forEach((it) => {
          if (it.field == "modeTypeValue") {
            it.data = res.data.zpms;
          } else if (it.field == "downType") {
            it.data = res.data.xzlx;
          }
        });
      });
    });
    searchCols_cars.value.forEach((it) => {
      if (it.key == "search_state") {
        it.data = res.data.fpzt;
      }
    });
  });
  // let ay_channel = [];
  // for (let i = 0; i < 17; i++) {
  //   ay_channel.push({
  //     name: i.toString(),
  //     value: i.toString(),
  //   });
  // }
  // formArray[10].data = ay_channel;
  //查询初始化
  proxy.$api.sys_init_datas({}).then((res) => {
    if (res.data.companys.length > 0) {
      let ay_companys = res.data.companys.map((it) => {
        return {
          name: it.cName,
          value: it.id,
        };
      });
      mainNav.forEach((ix) => {
        ix.form.forEach((iy) => {
          iy.children.forEach((it) => {
            if (it.field == "CompanyID") {
              it.data = ay_companys;
            }
          });
        });
      });
    }
  });
};

onMounted(() => {
  getList();
  init();
});

const multipleSelection = ref([]);

const formEvent = (obj) => {
  let rows;
  switch (obj.type) {
    case "rect":
      rows = obj.rows;
      mainNav.forEach((ix) => {
        ix.form.forEach((iy) => {
          iy.children.forEach((it) => {
            if (it.field == obj.to) {
              it.data = rows;
            }
            if (it.field == "areaNameValue") {
              it.val = "";
            }
          });
        });
      });
      break;
  }
};
const callbackEvent = (obj) => {
  // console.log(obj);
  switch (obj.type) {
    case "add":
    case "edit":
      // console.log("log", obj.row);
      if (obj.row.ID && obj.row.ID > 0) {
        curr_row.value = obj.row;
        console.log("wxw", obj.row);
        proxy.$api
          .item_get_rect({ cpy_id: obj.row.rCompanyID })
          .then((res_rect) => {
            let ay = [];
            if (res_rect.data.data.length > 0) {
              res_rect.data.data.forEach((it) => {
                it.name = it.rRectName;
                it.value = it.ID;
                ay.push(it);
              });
            }
            formEvent({
              type: "rect",
              rows: ay,
              to: "areaNameValue",
            });
          });
      } else {
        curr_row.value = {
          ID: 0,
          RuleName: "",
          CompanyID: "",
          travelTime: "240",
          restTime: "20",
          maxSpeed: "120",
          minSpeed: "0",
          beginTime: ["00:00:00", "23:59:59"],
          beginTime1: ["00:00:00", "23:59:59"],
          beginTime2: ["00:00:00", "23:59:59"],
          voice: "",
          camera: "0",
          cameraChannel: [],
          video: "0",
          videoChannel: [],
          mail: "",
          modeTypeValue: "0",
          interval: "60",
          video: "10",
        };
      }

      activeKey.value = "RulesForm";
      refAnyCom.value.dialogVisible = true;
      break;
    case "delete":
      proxy.$api.sys_rules_delete({ ids: obj.row.ID, t: 1 }).then((res) => {
        if (res.data.result == 1) {
          // proxy.$api
          //   .sys_orderdata_save({ ids: obj.row.id, t: 1 })
          //   .then((res_res) => {});
          ElNotification({
            title: t("commKey.MSG_TITLE"),
            message: t("commKey.DELETESUCCESS"),
            duration: 2000,
          });
        } else {
          ElNotification({
            title: t("commKey.MSG_TITLE"),
            message: t("commKey.DELETEFAIL"),
            duration: 2000,
          });
        }
        refresh_table("gps_rules", 5);
        getList();
      });
      break;

    case "search":
      Object.assign(query, obj.search);
      getList();
      break;
    case "export":
      Object.assign(query, obj.search);
      proxy.$api.sys_rules_grid_excel(query).then((res) => {
        // 处理返回的文件流
        const content = res.data;
        const blob = new Blob([content], {
          type: "application/vnd.ms-excel;charset=utf-8",
        });
        var date =
          new Date().getFullYear() +
          "" +
          (new Date().getMonth() + 1) +
          "" +
          new Date().getDate() +
          "" +
          new Date().getHours() +
          "" +
          new Date().getMinutes() +
          "" +
          new Date().getSeconds() +
          "" +
          new Date().getMilliseconds();
        const fileName = date + "." + "xlsx";
        if ("download" in document.createElement("a")) {
          // 非IE下载
          const elink = document.createElement("a");
          elink.download = fileName;
          elink.style.display = "none";
          elink.href = URL.createObjectURL(blob);
          document.body.appendChild(elink);
          elink.click();
          URL.revokeObjectURL(elink.href); // 释放URL 对象
          document.body.removeChild(elink);
        } else {
          // IE10+下载
          navigator.msSaveBlob(blob, fileName);
        }
        // console.log(res);
      });
      break;
    case "download":
      location.href = process.env.VUE_APP_BASE_URL + "Public/car.xlsx";
      break;

    case "upload":
      if (obj.result == 1) {
        ElNotification({
          title: t("commKey.MSG_TITLE"),
          message: t("commKey.IMPORTSUCCESS"),
          duration: 2000,
        });
      } else {
        ElNotification({
          title: t("commKey.MSG_TITLE"),
          message: t("commKey.IMPORTFAIL"),
          duration: 2000,
        });
      }
      getList();
      break;
    case "bind":
      rule_title.value = obj.row.rRuleName;
      proxy.$api
        .search_getGroupList({ company_id: obj.row.rCompanyID })
        .then((res) => {
          let ay = res.data.data;
          ay.forEach((it) => {
            it.name = it.gName;
            it.value = it.id;
          });
          searchCols_cars.value.forEach((it) => {
            if (it.key == "group_val") {
              it.data = ay;
            }
          });
          // console.log(res);
        });

      query_cars.value.cid = obj.row.rCompanyID;
      query_cars.value.rv_rid = obj.row.ID;
      query_cars.value.rv_zt = 1;
      query_cars.value.page = 1;
      getList_cars();
      // proxy.$api.sys_vehicles_grid(query_cars).then((res) => {
      //   tableData_cars.value = res.data.data;
      // });
      drawerShow.value = true;
      break;
  }
};
const saveEvent = (temp) => {
  if (temp.RuleType == 1014) {
    temp.CompanyID = 0;
    temp.BindID = 0;
  }
  proxy.$api.sys_rules_save(temp).then((res) => {
    ElNotification({
      title: t("commKey.MSG_TITLE"),
      message: t("commKey.MSG_" + res.data.msg_type),
      duration: 2000,
    });
    if (res.data.msg_type == 1 || res.data.msg_type == 2) {
      refAnyCom.value.dialogVisible = false;
      getList();
    }
    refresh_table("gps_rules", 5);
  });
};
const mainNav = reactive(cars.navForm);

mainNav.forEach((item) => {
  if (item.code == "1001") item.pos = item.pos2;
  else item.pos = item.pos1;
});

const search_val = ref("");

const clickTab = (it) => {
  mainNav.forEach((item) => {
    item.pos = item.pos1;
  });
  it.pos = it.pos2;
  //初始化数据

  formArray.value = it.form;
  dialogTitle.value = it.name;
  ruleType.value = it.code;
  query.qtype = it.code;
  getList();
  // console.log(it.name);
};

// function refresh_table(url, tables, min) {
//   // console.log('wxw','refresh_table');
//   var json = {
//     id: "11111-11111-11111-11111",
//     type: "refresh",
//     content: { tablename: tables, refreshtype: 5 },
//   };

//   $.post(
//     url,
//     {
//       id: 0,
//       oDeviceID: 0,
//       oDevType: 0,
//       oOrderType: "refresh",
//       oOrderContent: JSON.stringify(json),
//       oSendState: 0,
//       userid: userid,
//       token: token,
//     },
//     function (data) {}
//   );
//   mysocket.sendMsgToDB(
//     "refresh",
//     {
//       tablename: tables,
//       refreshtype: min,
//     },
//     function (json) {}
//   );
// }

//表单操作
const formArray = ref(mainNav[0].form);
const AyBindCar = ref([]);

const callbackEvent_cars = (obj) => {
  // console.log(obj);
  let ids = [];
  switch (obj.type) {
    case "search":
      let search_data = obj.search;
      query_cars.value.rv_zt = search_data.search_state;
      query_cars.value.group_id = search_data.group_val;
      query_cars.value.keyname = search_data.vPlate_val;
      getList_cars();
      break;
    case "multipleSelection":
      AyBindCar.value = obj.rows;
      break;
    case "bindcar":
      ids = AyBindCar.value.map((it) => it.id);
      proxy.$api
        .tree_rule_vehicle_process_exts({
          rid: query_cars.value.rv_rid,
          vids: ids.join(","),
          flag: 1,
        })
        .then((res) => {
          //下发网站更新表
          proxy.$api
            .sys_orderdata_save({}, "gps_rule_vehicle")
            .then((res_res) => {});
          //websocket
          GpsSocket.sendMsgToDB(
            "refresh",
            {
              tablename: "gps_rule_vehicle",
              refreshtype: 5,
            },
            () => {}
          );
          getList_cars();
          ElMessageBox.alert(t("map.gengxinchenggong"));
        });
      // console.log(obj, AyBindCar);
      break;
    case "unbindcar":
      ids = AyBindCar.value.map((it) => it.id);
      proxy.$api
        .tree_rule_vehicle_process_exts({
          rid: query_cars.value.rv_rid,
          vids: ids.join(","),
          flag: 2,
        })
        .then((res) => {
          //下发网站更新表
          proxy.$api
            .sys_orderdata_save({}, "gps_rule_vehicle")
            .then((res_res) => {});
          //websocket
          GpsSocket.sendMsgToDB(
            "refresh",
            {
              tablename: "gps_rule_vehicle",
              refreshtype: 5,
            },
            () => {}
          );

          getList_cars();
          ElMessageBox.alert(t("map.gengxinchenggong"));
        });
      // console.log(obj, AyBindCar);
      break;
    case "select":
      console.log(obj);
      break;
  }
};
const handleCurrentChange_cars = (val) => {
  query.page = val;
  getList_cars();
};

const getList_cars = () => {
  proxy.$api.sys_vehicles_grid(query_cars.value).then((res) => {
    tableData_cars.value = res.data.data;
    total_cars.value = res.data.pagination.totalRecords;
  });
};
</script>

<style lang="scss" scoped>
.gps-page {
  //padding: 10px;
  ::v-deep(.el-dialog__body) {
    padding: 0 10px;
  }
}
.navs {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  .item {
    width: 62px;
    cursor: pointer;
    .pic1 {
      background: url("../../assets/rule.png") no-repeat;
      width: 62px;
      height: 62px;
      background-position: -179px -2px;
    }
    .pic2 {
      background: url("../../assets/icon_s_for.png") no-repeat;
      width: 62px;
      height: 62px;
      background-position: -179px -2px;
    }
    .txt {
      text-align: center;
    }
  }
}

.search {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  .it {
    padding-right: 10px;
  }
}
.gps-page {
  background: #fff;
  border: 1px solid #ccc;
  padding: 0px !important;
}

.navs {
  background: #fff;
  padding: 10px;
  border-radius: 10px;
  margin: 10px;
  margin-bottom: 0px;
}
.cell .gps-span:nth-child(2) .is-round {
  background: red !important;
}
.gps-box {
  padding: 0px;
  border-left: none;
  border-right: none;
  border-bottom: none;
}
::v-deep .el-drawer.rtl .el-table--fit {
  border: 1px solid #e0dede !important;
}
::v-deep .el-drawer.rtl .gps-search {
  border: none !important;
  padding-left: 0px !important;
}
::v-deep .el-drawer.rtl .gps-item:nth-child(5) .el-button--success {
  background: #03b356 !important;
}
::v-deep .el-drawer.rtl .gps-item:nth-child(6) .el-button--success {
  background: #f9543e !important;
}
::v-deep .el-form-item__label {
  height: 24px;
  line-height: 24px;
}
</style>
